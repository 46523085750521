.footer {
	background: #fff;
	box-shadow: 0 2px 12px 0 rgba(0,0,0,.2);
	padding: 0.1rem;
	display: flex;
	justify-content: space-around;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 540px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 100;
	font-size: 0.27rem;
	filter: drop-shadow(0 -1px #25aae1);
}
.footer_item {
	width: 25%;
	flex: 1;
}
.icon_footer, .footer img {
	height: 25px;
	margin-bottom: 3px;
}
.footer a {
	color: #00aae6;
}
.footer .active a {
	color: #00aae6;
}