.greedy-game {
    background-image: url(./images/greedybigo_bg.f30881f.png);
    background-size: contain;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    min-height: 100%;
    max-width: 540px;
    padding: 0 10px;
}
.greedy-game .layer-title {
    background: #fbcf38;
    border-radius: 0 0 20px 20px;
    height: 60px;
    margin-bottom: 80px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;
}
.greedy-game .layer-title .round-game {
    background: #fee378;
    padding: 5px 10px;
    color: #333;
    height: 30px;
    line-height: 20px;
    margin-top: 15px;
    border-radius: 10px 0 0 10px;
    border: 1px solid #333;
    border-right: 0;
}
.greedy-game .layer-title .box-title {
    display: flex;
    padding: 10px 20px;
}
.greedy-game .layer-title .box-title .item {
    border: 6px double #333;
    background: #fcdb34;
    border-radius: 10px;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 35px;
    margin-right: 5px;
    overflow: hidden;
}
.greedy-game .layer-title .box-title .item-icon {
    background: #fff;
}
.greedy-game .middle-img {
    position: relative;
    height: 450px;
}
.greedy-game .middle-img > img {
    height: 100%;
    display: block;
    margin: 0 auto;
}
.greedy-game .middle-icon {
    width: 125px;
    height: 125px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 70px;
}
.greedy-game .middle-icon .text {
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 58px;
    text-align: center;
    color: #fff;
    font-size: 12px;
}
.greedy-game .middle-icon .text b {
    font-size: 16px;
    display: block;
    clear: both;
}
.greedy-game .icon {
    position: fixed;
    border-radius: 100%;
    border: 5px solid #3276d9;
    height: 90px;
    width: 90px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 2;
}
.greedy-game .icon.active {
    border-color: #ff3447;
    z-index: 1000;
}
.icon-border100 {
    border-radius: 100%;
    overflow: hidden;
    height: 80px;
    width: 80px;
}
.greedy-game .icon .image {
    display: block;
    height: 45px;
    text-align: center;
    background: #fff;
    position: relative;
}
.greedy-game .icon .image img {
    max-height: 40px;
    margin-top: 2px;
}
.greedy-game .hot-greedy {
    max-width: 30px;
    position: absolute;
    z-index: 1;
    animation: bounce 1.5s ease-in infinite alternate;
}
.greedy-game .icon .text {
    display: block;
    height: 45px;
    background: #8fdfff;
    color: #333;
    border-top: 1px solid #333;
    font-size: 11px;
    line-height: 14px;
    font-weight: bold;
}
.greedy-game .test {
    height: 200px;
    width: 200px;
    border: 1px solid #000;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;
}
.greedy-game .icon .text span {
    display: block;
    color: #d10606;
}
.greedy-game .icon .text span img {
    height: 12px;
    margin-right: 2px;
}
.greedy-game .icon.icon-1 {
    top: 80px;
}
.greedy-game .icon.icon-2 {
    top: 120px;
    margin-left: 100px;
}
.greedy-game .icon.icon-3 {
    top: 220px;
    margin-left: 145px;
}
.greedy-game .icon.icon-4 {
    top: 325px;
    margin-left: 105px;
}
.greedy-game .icon.icon-5 {
    top: 370px;
}
.greedy-game .icon.icon-6 {
    top: 325px;
    margin-left: -105px;
}
.greedy-game .icon.icon-7 {
    top: 220px;
    margin-left: -145px;
}
.greedy-game .icon.icon-8 {
    top: 120px;
    margin-left: -100px;
}
.greedy-game .icon.icon-9 {
    top: 422px;
    margin-left: -170px;
}
.greedy-game .icon.icon-10 {
    top: 422px;
    margin-left: 170px;
}
.greedy-game .icon-mini {
    height: 60px;
    width: 60px;
    overflow: unset;
}
.greedy-game .icon-mini .image {
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}
.greedy-game .icon-mini .image img {
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 36px;
    max-height: 36px;
    left: 7px;
    top: 7px;
}
.greedy-game .icon-mini .text {
    background: #fdfc32;
    border: 1px solid #333;
    border-radius: 4px;
    color: #333;
    width: 120%;
    margin-left: -10%;
    margin-top: -5px;
    height: unset;
    position: relative;
}
.greedy-game .bottom-select {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    max-width: 240px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: 5px;
}
.greedy-game .bottom-select .bottom-choose {
    width: 20%;
    height: 100%;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: grayscale(1);
    cursor: pointer;
}
.greedy-game .bottom-select .bottom-choose.active {
    filter: brightness(1.2);
}
.greedy-game .greedy-button {
    margin: 10px auto 0;
    max-width: 420px;
    display: flex;
}
.greedy-game .greedy-reset {
    background: #d1d3d0;
    color: #2d2400;
    border: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid #a2ab9f;
    padding: 10px 15px;
    white-space: nowrap;
    cursor: pointer;
    width: 100px;
    margin-right: 10px;
}
.greedy-game .greedy-submit {
    background: #a5ff82;
    color: #2d2400;
    border: 0;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    border-radius: 10px;
    border: 3px solid #79eb4d;
    padding: 10px 45px;
    white-space: nowrap;
    cursor: pointer;
    width: 100%
}
.greedy-stats {
    font-size: 16px;
    margin-top: 10px;
}
.greedy-stats b {
    color: #ff3447;
}
.greedy-bangketqua {
    position: fixed;
    z-index: 999;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 540px;
    background: #8fdfff;
    border-radius: 15px;
    padding: 30px 15px 15px;
}
.greedy-ketqua {
    background: #fff;
    border-radius: 10px 10px 0 0;
    padding: 10px 0;
    min-height: 200px;
    position: relative;
}
.greedy-bangketqua-close {
    position: absolute;
    top: -32px;
    right: -5px;
    height: 20px;
    width: 20px;
    font-size: 25px;
    color: #333;
    cursor: pointer;
}
.greedy-result-list {
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    padding: 5px 10px;
    background: #e6535b;
    width: 100%;
    max-width: 420px;
    margin: 15px auto;
    white-space: nowrap;
}
.greedy-result-list div img:nth-child(n + 9) {
    display: none;
}
.greedy-result-list img {
    margin: 3px 0 0 5px;
    height: 35px;
}
.backdrop-countdown {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 540px;
    height: 100vh;
    background: #000000c2;
    z-index: 999;
    display: flex;
    padding-top: 300px;
    justify-content: center;
    color: #eee;
}
.icon-coin {
    background: url(./images/icon-coin.png);
    background-size: 100%;
    height: 90%;
    width: 90%;
    display: flex;
    align-items: center;
    color: #333;
    font-size: 14px;
    justify-content: center;
    font-weight: bold;
}
.bangxephang {
    color: #333;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 6px 10px;
    background: #fff;
    width: 100%;
    max-width: 420px;
    margin: 15px auto;
    text-align: left;
}
.bangxephang div:first-child img {
    height: 30px;
    width: auto;
    margin-right: 15px;
}
.bangxephang h3 {
    font-weight: bold;
    margin-bottom: 3px;
}
.bangxephang div:last-child {
    margin-left: auto;
}
.bangxephang button {
    background: #fbcf38;
    color: #333;
    font-size: 12px;
    border: 0;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}
.bangxephang.bxh {
    background: #97ffbc;
    margin: 20px 15px -15px;
    max-width: calc(100% - 30px);
}
.popup-bxh.bxh {
    background: #97ffbc;
}
.popup-bxh {
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 540px;
    background: #8fdfff;
    border-radius: 15px 15px 0 0;
    padding: 15px;
}
.popup-bxh-close {
    position: absolute;
    top: 5px;
    right: 10px;
    height: 20px;
    width: 20px;
    font-size: 25px;
    color: #333;
    cursor: pointer;
}
.popup-bxh-title {
    color: #052c67;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
}
.popup-bxh-content {
    max-height: 310px;
    overflow-x: hidden;
    overflow-y: auto;
}
.popup-bxh-content::-webkit-scrollbar {
    display: none;
}
.popup-bxh-detail {
    background: #fff;
    display: flex;
    font-size: 16px;
    text-align: left;
    align-items: center;
    padding: 6px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-weight: bold;
}
.popup-bxh-detail div:first-child span {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-right: 10px;
    border-radius: 100%;
    background: #bbb;
}
.popup-bxh-detail div:last-child {
    margin-left: auto;
}
.popup-bxh-detail div:last-child img {
    position: relative;
    top: 3px;
    margin-right: 5px;
}
.popup-bxh-detail strong {
    color: #052c67;
}
@media (max-width: 400px) {
    .greedy-scale {
        transform: scale(0.9);
    }
    html:has(.greedy-game) {
        background-image: url(./images/greedybigo_bg.f30881f.png);
        background-size: contain;
    }
    body,
    .App,
    .main {
        background: transparent;
    }
    .greedy-game {
        background: none;
    }
    .main .header:after {
        height: 1.22667rem;
        border-radius: 0;
    }
    .greedy-result-list img {
        margin: 3px 0 0 3px;
        height: 25px;
    }
}
